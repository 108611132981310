import React from 'react'

function Hero() {
    return (
        <div>
            <div className='row banner'>
                <div className="banner-text">
                    <h1 className="responsive-headline">Hello Marcos,</h1>
                    <p>I invite you to collaborate with me on this project originated by you. It would be a great pleasure to help you complete this and see it become a reality.
                        I have drafted a framework and a structure to make this possible. It truly is a bright idea and nothing would be better than having the fan himself make decisions
                        to bring this to life. I hope you decide to take part in it. Please consider it. No matter what, we'd make a great team.
                    </p>
                    <p>-Lupe </p>
                </div>
            </div>
        </div>
    )
}

export default Hero
