import React from 'react'

export default function Header() {
    return (
        <header id="home">
            <nav id="nav-wrap">
                <ul id="nav" className="nav">
                    <li className="current">
                        <a className="smoothscroll" href="#home">
                            Home
                        </a>
                    </li>

                    <li>
                        <a className="smoothscroll" href="#about">
                            About
                        </a>
                    </li>

                    <li>
                        <a className="smoothscroll" href="#signup">
                            Sign Up
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    )
}
